
import { defineComponent } from 'vue'
import axios from 'axios'
import dayjs from 'dayjs'
import store from '@/store/index'
import searchComponent from '@/components/search-component/index.vue'
import tableComponent from '@/components/table-component/index.vue'
import { downloadPayrollRecordElepersonal } from '@/api/operation'
import { getEmployeeSalary, exportEmployeeSalaryRecord } from '@/api/salary'
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'
import { payrollRecordOwnExport } from '@/api/export'
import { dateFormat, closewin, downLoadFile } from '@/utils/util'
const startMonth = dayjs().subtract(1, 'month').format('YYYY-MM')
const endMonth = dayjs().format('YYYY-MM')
const startDay = dayjs().startOf('month').format('YYYY-MM-DD')
const endDay = dayjs().endOf('month').format('YYYY-MM-DD')

export default defineComponent({
    name: 'PayrollRecordOwn',
    components: {
        searchComponent,
        tableComponent
    },
    data() {
        return {
            tempDate: '',
            searchList: {
                searchText: {
                    name: '搜索员工',
                    prop: 'searchText',
                    type: 'input',
                    placeholder: '请输入姓名/手机号/身份证',
                    value: '',
                    defaultValue: ''
                },
                queryMonth: {
                    name: '发薪时间',
                    prop: 'PayTime',
                    type: 'dateRange',
                    dateType: 'dateRange',
                    startPlaceholder: '开始时间',
                    endPlaceholder: '结束时间',
                    value: [startDay, endDay],
                    defaultValue: [startDay, endDay],
                    valueFormat: 'YYYY-MM-DD',
                    unClearable: true,
                    handleCalendarChange: (ev: any) => {
                        if (ev[0]) {
                            (this as any).tempDate = dayjs(ev[0]).format('YYYY-MM-DD')
                        }
                    },
                    handleChange: () => {
                        (this as any).tempDate = ''
                    },
                    disableDate: (time: any) => {
                        if (this.tempDate) {
                            const fromDate = dayjs((this as any).tempDate).subtract(31, 'day').format('YYYY-MM-DD')
                            const toDate = dayjs((this as any).tempDate).add(31, 'day').format('YYYY-MM-DD')
                            const formatTime = dayjs(time).format('YYYY-MM-DD')

                            return formatTime <= fromDate || formatTime >= toDate
                        }
                        return false
                    }
                }
                // queryMonth: {
                //   name: '发薪月份',
                //   prop: 'queryMonth',
                //   type: 'date',
                //   dateType: 'monthrange',
                //   startPlaceholder: '开始时间',
                //   endPlaceholder: '结束时间',
                //   value: [startMonth, endMonth],
                //   defaultValue: [startMonth, endMonth],
                //   valueFormat: 'YYYY-MM',
                //   unClearable: true
                // },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            buttonList: [
                {
                    name: '导出明细',
                    icon: 'export',
                    fn: () => {
                        this.onExport()
                    }
                }
            ] as any,
            tableHead: [
                {
                    prop: 'index'
                },
                {
                    prop: 'date',
                    label: '月份',
                    width: 220
                },
                {
                    prop: 'userName',
                    label: '姓名',
                    width: 100
                },
                {
                    prop: 'phone',
                    label: '手机号',
                    width: 120
                },
                {
                    prop: 'idCardNo',
                    label: '身份证号',
                    width: 190
                },
                {
                    prop: 'totalPayCount',
                    label: '发薪单数'
                },
                {
                    prop: 'realHairAmount',
                    label: '实发金额',
                    prefix: '¥'
                },
                {
                    prop: 'stopPay',
                    label: '停发金额',
                    prefix: '¥'
                },
                {
                    prop: 'received',
                    label: '已领金额',
                    prefix: '¥'
                },
                {
                    prop: 'unclaimed',
                    label: '待领金额',
                    prefix: '¥'
                },
                {
                    prop: 'operate',
                    label: '操作',
                    width: 200,
                    fixed: 'right'
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            tableData: [
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
            selects: [],
            ids: []
        }
    },
    computed: {
        disableDate() {
            return {
                disabledDate(time: any) {
                    console.log(time)
                }
            }
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        handleSelectionChange(row: any) {
            this.selects = row.map((i: any) => i.idCardNo)
            this.ids = row.map((i: any) => i.id)
        },
        createQuery(hasPage = true) {
            const query = {} as any
            if (hasPage) {
                query.pageNum = this.pageInfo.pageNum
                query.pageSize = this.pageInfo.pageSize
            }
            for (const key in this.searchList) {
                if (this.searchList[key]?.dateType?.includes('Range')) {
                    query[`begin${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[0] : ''
                    query[`end${this.searchList[key].prop}`] = this.searchList[key].value?.length ? this.searchList[key].value[1] : ''
                } else {
                    query[(this.searchList[key]).prop] = this.searchList[key].value
                }
            }
            return query
        },
        handleSearch() {
            this.pageInfo.pageNum = 1
            this.getData()
        },
        handleSizeChange(ev: number) {
            this.pageInfo.pageSize = ev
            this.getData()
        },
        handleCurrentChange(ev: number) {
            this.pageInfo.pageNum = ev
            this.getData()
        },
        handleArchive(row: any) {
            this.$router.push({
                path: '/staffBillDetail',
                query: {
                    id: btoa(row.id),
                    cardId: btoa(row.idCardNo),
                    queryMonth: btoa(this.searchList.queryMonth.value)
                }
            })
        },
        getData() {
            const query = this.createQuery()
            getEmployeeSalary(query).then((res: any) => {
                this.tableData = res.list || []
                // this.tableData.forEach((row: any) => {
                //   row.date = dayjs(row.date).format('YYYY-MM')
                // })
                this.pageInfo.total = res.total
            })
        },

        async onExport() {
            if (!this.searchList.queryMonth.value) {
                this.$message.error('请选择时间')
                return
            }
            const result = await payrollRecordOwnExport({
                // queryMonth: this.searchList.queryMonth.value,
                beginPayTime: this.searchList.queryMonth.value[0],
                endPayTime: this.searchList.queryMonth.value[1],
                searchText: this.searchList.searchText.value
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response: any) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
        },
        windowOpen() {
            window.open(
                window.location.pathname +
                '?tip#/pay'
            )
            const channel = new BroadcastChannel('new_tab_pay')
            const timer = setInterval(() => {
                const query = {
                    activeName: 'pay',
                    searchList: this.searchList,
                    pageInfo: this.pageInfo,
                    selects: this.selects.join(',')
                }
                channel.postMessage(JSON.stringify(query))
                console.log('postMessage')
            }, 50)
            channel.onmessage = e => {
                if (e.data === 'pay_close') {
                    clearInterval(timer)
                    channel.close()
                }
            }
        },
        async handleExportWechatReceipt(row: any) {
            if (!this.searchList.queryMonth.value) {
                this.$message.error('请选择时间')
                return
            }
            const result = await downloadPayrollRecordElepersonal({
                staus: 1,
                idCardNo: row.idCardNo,
                beginPayTime: this.searchList.queryMonth.value[0],
                endPayTime: this.searchList.queryMonth.value[1],
                isError: 0
            })
            this.windowOpen()
            document.title = '正在下载中...'
            const loading = await ElLoading.service({
                fullscreen: true,
                text: '回单正在下载中，您可以在新界面继续操作'
            })
            const files = await axios({
                url:
                    'https://1403244436326619.cn-shanghai.fc.aliyuncs.com/2016-08-15/proxy/zip-service/zip-oss/',
                method: 'post',
                data: result
            })
            window.location.href = files.data
            await this.$message.success('导出成功！')
            await loading.close()
            closewin()
        },
        async handleExport(row: any) {
            // console.log(row, 'hyusfhuyg')
            // this.onExport([row.id])
            if (!this.searchList.queryMonth.value) {
                this.$message.error('请选择时间')
                return
            }
            const result = await payrollRecordOwnExport({
                // queryMonth: this.searchList.queryMonth.value,
                beginPayTime: this.searchList.queryMonth.value[0],
                endPayTime: this.searchList.queryMonth.value[1],
                searchText: row.userName
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response: any) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
        }
    }
})
