import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "approval-box" }
const _hoisted_2 = { class: "timeline-box" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "user-name" }
const _hoisted_6 = { class: "user-occupation" }
const _hoisted_7 = { class: "user-remarks" }
const _hoisted_8 = { class: "submit-time" }
const _hoisted_9 = { class: "time" }
const _hoisted_10 = { class: "row row-active-top" }
const _hoisted_11 = { class: "align-items" }
const _hoisted_12 = { class: "user-name" }
const _hoisted_13 = { class: "user-occupation" }
const _hoisted_14 = { class: "user-remarks" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "align-items" }
const _hoisted_17 = {
  class: "align-items",
  style: {"align-items":"flex-start"}
}
const _hoisted_18 = { class: "submit-time" }
const _hoisted_19 = { class: "time" }
const _hoisted_20 = {
  key: 0,
  class: "tips-box"
}
const _hoisted_21 = {
  key: 0,
  class: "btn-popup-box"
}
const _hoisted_22 = {
  key: 1,
  class: "btn-popup-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_timeline = _resolveComponent("el-timeline")!
  const _component_alertBox = _resolveComponent("alertBox")!

  return (_ctx.isApprovalPopup)
    ? (_openBlock(), _createBlock(_component_alertBox, {
        key: 0,
        "data-title": "审批状态",
        close: "x",
        onClose: _ctx.onCloseApproval
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_timeline, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_timeline_item, { color: "#1989FA" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.approvalInfo.createUserName), 1),
                            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.approvalInfo.position), 1)
                          ]),
                          _createElementVNode("div", _hoisted_7, " 备注：" + _toDisplayString(_ctx.approvalInfo.remarks || '暂无备注'), 1)
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.approvalInfo.createTime), 1),
                          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "submit-status" }, "提交人", -1))
                        ])
                      ])
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.approvalInfo.approveDetails, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_timeline_item, {
                      color: "#1989FA",
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, _toDisplayString(item.userName), 1),
                                _createElementVNode("div", _hoisted_13, _toDisplayString(item.position), 1)
                              ]),
                              _createElementVNode("div", _hoisted_14, _toDisplayString(item.auditContent ? '备注：' + item.auditContent : ''), 1),
                              (!item.createTime && (_ctx.userId === item.userId) && (_ctx.approvalInfo.flow === item.approverSeq) && !_ctx.approvalInfo.isEnd)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    _createElementVNode("div", null, [
                                      _createElementVNode("div", _hoisted_16, [
                                        _cache[8] || (_cache[8] = _createElementVNode("div", null, "是否同意：", -1)),
                                        _createElementVNode("div", null, [
                                          _createVNode(_component_el_radio_group, {
                                            modelValue: _ctx.approvalRadio,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.approvalRadio) = $event)),
                                            class: "ml-4"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_el_radio, {
                                                label: "1",
                                                size: "large"
                                              }, {
                                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                  _createTextVNode("同意")
                                                ])),
                                                _: 1
                                              }),
                                              _createVNode(_component_el_radio, {
                                                label: "2",
                                                size: "large"
                                              }, {
                                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                  _createTextVNode("驳回")
                                                ])),
                                                _: 1
                                              })
                                            ]),
                                            _: 1
                                          }, 8, ["modelValue"])
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_17, [
                                        _cache[9] || (_cache[9] = _createElementVNode("div", null, "审核备注：", -1)),
                                        _createElementVNode("div", null, [
                                          _createVNode(_component_el_input, {
                                            placeholder: "请输入审核备注",
                                            style: {"width":"300px"},
                                            rows: 3,
                                            modelValue: _ctx.textArea,
                                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.textArea) = $event)),
                                            type: "textarea"
                                          }, null, 8, ["modelValue"])
                                        ])
                                      ])
                                    ]),
                                    _cache[10] || (_cache[10] = _createElementVNode("div", null, null, -1))
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("div", _hoisted_19, _toDisplayString(item.createTime || '未审核'), 1),
                              _createElementVNode("div", {
                                class: _normalizeClass([item.state === 1 ? 'submit-green' : item.state === 2 ? 'submit-orange' : '', 'submit - status'])
                              }, _toDisplayString((item.state === 1 ? '已通过' : (item.state === 2 ? '已拒绝' : ''))), 3)
                            ])
                          ]),
                          (_ctx.approvalInfo.isEnd && (_ctx.approvalInfo.approveDetails.length - 1 === index))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createVNode(_component_el_alert, {
                                  title: "提示：为保护账户安全，请您去手机端审批！",
                                  closable: false,
                                  type: "error",
                                  "show-icon": ""
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            (_ctx.approvalInfo.state === 0 && _ctx.userId === _ctx.approvalInfo.createUserId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("button", {
                    class: "mini-btn",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCancelApply && _ctx.onCancelApply(...args)))
                  }, "取消申请")
                ]))
              : _createCommentVNode("", true),
            (_ctx.approvalInfo.state === 4 && !_ctx.approvalInfo.isEnd)
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "btn-white mini-btn mini-btn-white",
                      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onCloseApproval && _ctx.onCloseApproval(...args)))
                    }, "取消"),
                    _createElementVNode("button", {
                      class: "mini-btn mini-btn-active",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onDefineApproval && _ctx.onDefineApproval(...args)))
                    }, "确认")
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["onClose"]))
    : _createCommentVNode("", true)
}