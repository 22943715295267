export const statusOptions = [
  {
    label: '进行中',
    value: 0
  },
  {
    label: '已通过',
    value: 1
  },
  {
    label: '已驳回',
    value: 2
  },
  {
    label: '已取消',
    value: 3
  },
  {
    label: '待我审批',
    value: 4
  },
  {
    label: '我参与的',
    value: 5
  },
  {
    label: '全部',
    value: ''
  }
] as any[]
