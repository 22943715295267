import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "flex flex-ai-c" }
const _hoisted_6 = { class: "flex flex-ai-c justify-center" }
const _hoisted_7 = { class: "flex flex-ai-c" }
const _hoisted_8 = { class: "flex flex-ai-c justify-center" }
const _hoisted_9 = {
  key: 0,
  class: "bold fs-20 text-center mg-b-16"
}
const _hoisted_10 = {
  key: 1,
  class: "bold fs-20 text-center mg-b-40"
}
const _hoisted_11 = {
  key: 2,
  class: "icon-3 text-center mg-b-40"
}
const _hoisted_12 = { class: "flex flex-ai-c justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_searchComponent = _resolveComponent("searchComponent")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_tableComponent = _resolveComponent("tableComponent")!
  const _component_batchPay = _resolveComponent("batchPay")!
  const _component_dialogApproval = _resolveComponent("dialogApproval")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "container" }, {
    default: _withCtx(() => [
      _createVNode(_component_searchComponent, {
        "search-list": _ctx.searchList,
        "onUpdate:searchList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchList) = $event)),
        "button-list": _ctx.buttonList,
        onDoSearch: _ctx.handleSearch
      }, null, 8, ["search-list", "button-list", "onDoSearch"]),
      _createVNode(_component_tableComponent, {
        class: "mg-t-10",
        "table-data": _ctx.tableData,
        "table-head": _ctx.tableHead,
        "row-key": "id",
        onSelectionChange: _ctx.handleSelectionChange,
        "page-info": _ctx.pageInfo,
        onSizeChange: _ctx.handleSizeChange,
        onCurrentChange: _ctx.handleCurrentChange
      }, {
        status: _withCtx((scope) => [
          _createVNode(_component_el_button, {
            type: "text",
            link: "",
            class: _normalizeClass([`icon-${scope.row.state}`]),
            onClick: ($event: any) => (_ctx.onIsApprovalPopup(scope.row))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(scope.row.status), 1)
            ]),
            _: 2
          }, 1032, ["class", "onClick"])
        ]),
        payAccountFlag: _withCtx((scope) => [
          (scope.row.payAccountFlag === 10001 && scope.row.status !== '进行中')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, "微信支付"))
            : _createCommentVNode("", true),
          (scope.row.payAccountFlag === 20001 && scope.row.status !== '进行中')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, "支付宝支付"))
            : _createCommentVNode("", true),
          (scope.row.status === '进行中')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "--"))
            : _createCommentVNode("", true)
        ]),
        operate: _withCtx((scope) => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_button, {
              class: "tep-3",
              type: "text",
              size: "small",
              onClick: _withModifiers(($event: any) => (_ctx.handleArchive(scope.row)), ["stop"])
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createTextVNode("查看")
              ])),
              _: 2
            }, 1032, ["onClick"]),
            ((scope.row.isCreateReceipt === 0 && scope.row.state === 1) || scope.row.isCreateReceipt === 3)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  type: "text",
                  size: "small",
                  onClick: ($event: any) => (_ctx.onApplyEle(scope.row.id))
                }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode("申请回单 ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            (scope.row.isCreateReceipt === 1)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 1,
                  type: "text",
                  class: "icon-1",
                  size: "small",
                  onClick: ($event: any) => (_ctx.openUrl(scope.row.receiptUrl))
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode("下载回单 ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_button, {
              type: "text",
              size: "small",
              onClick: ($event: any) => (_ctx.doExport(scope.row.id))
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("导出明细")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        _: 1
      }, 8, ["table-data", "table-head", "onSelectionChange", "page-info", "onSizeChange", "onCurrentChange"]),
      (_ctx.isBatchPay)
        ? (_openBlock(), _createBlock(_component_batchPay, {
            key: 0,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isBatchPay = false)),
            onComplete: _ctx.getData,
            isType: "pay"
          }, null, 8, ["onComplete"]))
        : _createCommentVNode("", true),
      _createVNode(_component_dialogApproval, {
        show: _ctx.isApprovalPopup,
        "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isApprovalPopup) = $event)),
        "approval-id": _ctx.approvalId,
        "user-id": _ctx.userId,
        onDoSearch: _ctx.getData
      }, null, 8, ["show", "approval-id", "user-id", "onDoSearch"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.isUpapliy,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isUpapliy) = $event)),
        title: "申请回单",
        width: "500px"
      }, {
        default: _withCtx(() => [
          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "mg-t--20 icon-3" }, "只可申请已领薪人员的回单", -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "mg-r-12 bold" }, "回单类型", -1)),
            _createVNode(_component_el_radio_group, {
              modelValue: _ctx.radio1,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.radio1) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, {
                  value: 1,
                  size: "large",
                  label: 1
                }, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createTextVNode("子弹回单")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_radio, {
                  value: 2,
                  size: "large",
                  label: 2
                }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createTextVNode("微信\\支付宝回单")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _cache[21] || (_cache[21] = _createElementVNode("div", {
            style: {"color":"#FF7642"},
            class: "mg-b-20"
          }, "*请至少选择一条", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_button, {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isUpapliy = false))
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [
                _createTextVNode("取消")
              ])),
              _: 1
            }),
            _cache[19] || (_cache[19] = _createTextVNode()),
            _createVNode(_component_el_button, { type: "primary" }, {
              default: _withCtx(() => _cache[18] || (_cache[18] = [
                _createTextVNode("申请回单")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.isUpexport,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.isUpexport) = $event)),
        title: "下载回单",
        width: "500px"
      }, {
        default: _withCtx(() => [
          _cache[28] || (_cache[28] = _createElementVNode("div", { class: "mg-t--20 icon-3" }, "只可下载申请已通过的回单", -1)),
          _createElementVNode("div", _hoisted_7, [
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "mg-r-12 bold" }, "回单类型", -1)),
            _createVNode(_component_el_radio_group, {
              modelValue: _ctx.radio1,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.radio1) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_radio, {
                  value: 1,
                  size: "large",
                  label: 1
                }, {
                  default: _withCtx(() => _cache[22] || (_cache[22] = [
                    _createTextVNode("子弹回单")
                  ])),
                  _: 1
                }),
                _createVNode(_component_el_radio, {
                  value: 2,
                  size: "large",
                  label: 2
                }, {
                  default: _withCtx(() => _cache[23] || (_cache[23] = [
                    _createTextVNode("微信\\支付宝回单")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _cache[29] || (_cache[29] = _createElementVNode("div", {
            style: {"color":"#FF7642"},
            class: "mg-b-20"
          }, "*请至少选择一条", -1)),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_button, {
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isUpexport = false))
            }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode("取消")
              ])),
              _: 1
            }),
            _cache[27] || (_cache[27] = _createTextVNode()),
            _createVNode(_component_el_button, { type: "primary" }, {
              default: _withCtx(() => _cache[26] || (_cache[26] = [
                _createTextVNode("下载回单")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.isUpwait,
        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isUpwait) = $event)),
        title: "",
        width: "500px"
      }, {
        default: _withCtx(() => [
          true
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, "回单生成中···"))
            : _createCommentVNode("", true),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, "回单已成中"))
            : _createCommentVNode("", true),
          true
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, "预计5分钟内完成"))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_el_button, null, {
              default: _withCtx(() => _cache[30] || (_cache[30] = [
                _createTextVNode("返回列表")
              ])),
              _: 1
            }),
            _cache[32] || (_cache[32] = _createTextVNode()),
            _createVNode(_component_el_button, { type: "primary" }, {
              default: _withCtx(() => _cache[31] || (_cache[31] = [
                _createTextVNode("下载回单")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}