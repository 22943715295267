import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "nickName" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_searchComponent = _resolveComponent("searchComponent")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_tableComponent = _resolveComponent("tableComponent")!
  const _component_batchPay = _resolveComponent("batchPay")!
  const _component_dialogApproval = _resolveComponent("dialogApproval")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, { class: "container" }, {
    default: _withCtx(() => [
      _createVNode(_component_searchComponent, {
        "search-list": _ctx.searchList,
        "onUpdate:searchList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchList) = $event)),
        "button-list": _ctx.buttonList,
        onDoSearch: _ctx.handleSearch
      }, null, 8, ["search-list", "button-list", "onDoSearch"]),
      _createVNode(_component_tableComponent, {
        ref: "table",
        class: "mg-t-10",
        "table-data": _ctx.tableData,
        "table-head": _ctx.tableHead,
        "row-key": "id",
        onSelectionChange: _ctx.handleSelectionChange,
        "page-info": _ctx.pageInfo,
        onSizeChange: _ctx.handleSizeChange,
        onCurrentChange: _ctx.handleCurrentChange
      }, {
        userName: _withCtx((scope) => [
          _createElementVNode("div", null, _toDisplayString(scope.row.userName), 1),
          (scope.row.nickName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_tooltip, {
                  class: "box-item",
                  effect: "dark",
                  content: "此处为备注名",
                  placement: "top-start"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(scope.row.nickName ? '(' + scope.row.nickName + ')' : ''), 1)
                  ]),
                  _: 2
                }, 1024)
              ]))
            : _createCommentVNode("", true)
        ]),
        payAccountFlag: _withCtx((scope) => [
          (scope.row.payAccountFlag === 10001)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "微信支付"))
            : _createCommentVNode("", true),
          (scope.row.payAccountFlag === 20001)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "支付宝支付"))
            : _createCommentVNode("", true)
        ]),
        auditStatus: _withCtx((scope) => [
          _createElementVNode("div", {
            class: _normalizeClass(`icon-${scope.row.auditStatus}`)
          }, _toDisplayString((scope.row.auditStatus === 1 ? '审核通过' : scope.row.auditStatus === 2 ? '审核失败' : '审核中')), 3)
        ]),
        statusMsg: _withCtx((scope) => [
          _createElementVNode("div", {
            class: _normalizeClass(`icon-${scope.row.status}`)
          }, _toDisplayString(scope.row.statusMsg), 3)
        ]),
        billStatus: _withCtx((scope) => [
          _createElementVNode("div", {
            class: _normalizeClass([`billstatus-${scope.row.billStatus}`])
          }, _toDisplayString((scope.row.billStatus === null ? '未申请' : scope.row.billStatus === 1 ? '申请中' :
                        scope.row.billStatus === 2 ?
                            '已完成' : '未申请')), 3)
        ]),
        _: 1
      }, 8, ["table-data", "table-head", "onSelectionChange", "page-info", "onSizeChange", "onCurrentChange"]),
      (_ctx.isBatchPay)
        ? (_openBlock(), _createBlock(_component_batchPay, {
            key: 0,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isBatchPay = false)),
            onComplete: _ctx.getData,
            isType: "pay"
          }, null, 8, ["onComplete"]))
        : _createCommentVNode("", true),
      _createVNode(_component_dialogApproval, {
        show: _ctx.isApprovalPopup,
        "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isApprovalPopup) = $event)),
        "approval-id": _ctx.approvalId,
        "user-id": _ctx.userId,
        onDoSearch: _ctx.getData
      }, null, 8, ["show", "approval-id", "user-id", "onDoSearch"])
    ]),
    _: 1
  }))
}